import { Box, Button, Container, Input, Typography } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { useTypedSelector } from '../../../../store/store';
import { CampaignTypes } from '../../../../api/models/rewards';
import { SignUpScreenStrings } from '../../../localization/en';

interface UserInfoBlockProps {
  onClick: () => void;
}
const useStyles = makeStyles(() => {
  return {
    Container: {
      maxWidth: '540px',
      width: '100%',
      left: '50%',
      paddingLeft: 0,
      paddingRight: 0,
      background: '#fff',
      height: 'var(--app-height)',
      minHeight: '550px',
    },
    Button: {
      color: '#2D11AD',
      borderColor: '#2D11AD',
      width: '152px',
    },
    Question: {
      color: '#475467',
      fontSize: '18px',
      margin: '30px 0px',
      //   width: '75%',
    },
    formLabel: {
      color: '#475467',
      fontSize: '18px',
      fontWeight: 400,
      margin: '16px 8px',
    },
    AuthSubmitBtn: () => ({
      marginTop: '12px',
      backgroundColor: '#2D11AD',
      color: '#fff',
      width: '100%',
      height: '12.2vw',
      maxWidth: '442px',
      maxHeight: '52px',
      fontSize: 'clamp(16px, 4.4vw, 24px)',
      fontWeight: 400,
      '&:hover': {
        backgroundColor: '#2D11AD',
        boxShadow: 'unset',
      },
    }),
    input: {
      width: '100%',
      height: '12.2vw',
      maxWidth: '442px',
      maxHeight: '52px',
      border: '1px solid',
      borderRadius: '8px',
      borderColor: '#D0D5DD',
      marginBottom: '10px',

      paddingLeft: '12px',
      color: '#2D11AD',
      '& .MuiInput-input:-webkit-autofill, .MuiInput-input:-webkit-autofill:focus, .MuiInput-input:-webkit-autofill:hover':
        {
          WebkitTextFillColor: '#2D11AD',
          fontSize: 'clamp(16px, 4.4vw, 24px)',
        },
      '& input': {
        color: '#2D11AD !important',
        fontSize: 'clamp(16px, 4.4vw, 24px)',
        '&::placeholder': {
          color: '#A9B3C8',
        },
      },
      '&.Mui-error': {
        borderColor: '#ED2E38',
      },
    },
  };
});
export const UserInfoBlock: FC<UserInfoBlockProps> = ({ onClick }) => {
  const classes = useStyles();
  const { currentCampaign } = useTypedSelector((state) => state.rewards);
  const { firstName, lastName, phoneNumber } = useTypedSelector((state) => state.me);

  const isCustomRewardCampaign = currentCampaign?.campaignType === CampaignTypes.CUSTOM_REWARD;
  const isMonthlyGiveawayCampaign = currentCampaign?.campaignType === CampaignTypes.MONTHLY_GIVEAWAY;
  const isNoRewardCampaign = currentCampaign?.campaignType === CampaignTypes.NO_REWARD;

  return (
    <Container
      sx={{
        position: 'absolute',
        zIndex: 2,
        transform: `translateX(-50%)`,
        marginTop: '-20px',
      }}
      className={classes.Container}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'flex-start'}
        flexDirection={'column'}
        padding="30px 30px"
      >
        {isNoRewardCampaign && (
          <Typography className={classes.formLabel}>{SignUpScreenStrings.ConfirmNoReward}</Typography>
        )}
        {isMonthlyGiveawayCampaign && (
          <Typography className={classes.formLabel}>
            {SignUpScreenStrings.ConfirmEntry}{' '}
            <span style={{ fontWeight: 'bold' }}>{SignUpScreenStrings.ConfirmEntryBold}</span>
          </Typography>
        )}
        {isCustomRewardCampaign && (
          <>
            <p style={{ fontWeight: '400', color: '#475467', fontSize: '18px', margin: '5px' }}>
              {SignUpScreenStrings.ConfirmEntryForCustom}
            </p>
            <p style={{ fontWeight: '600', color: '#475467', fontSize: '18px', margin: '5px' }}>
              {currentCampaign.rewardTitle}
            </p>
          </>
        )}

        <Box display={'flex'} gap="10px">
          <Input
            className={classes.input}
            value={firstName}
            disableUnderline={true}
            name="firstName"
            placeholder={SignUpScreenStrings.First}
            disabled
          />

          <Input
            className={classes.input}
            value={lastName}
            disableUnderline={true}
            name="lastName"
            placeholder={SignUpScreenStrings.Last}
            disabled
          />
        </Box>
        <Input
          className={classes.input}
          value={phoneNumber}
          disableUnderline={true}
          name="phoneNumber"
          placeholder={SignUpScreenStrings.Last}
          style={{ width: '100%' }}
          disabled
        />
        <Button
          className={classes.AuthSubmitBtn}
          sx={{
            height: '48px',
            borderRadius: '12px',
          }}
          variant="contained"
          fullWidth
          onClick={onClick}
        >
          Next
        </Button>
      </Box>
    </Container>
  );
};
