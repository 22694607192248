import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { useShareStory } from './hooks/useShareStory';
import { httpClient } from '../../services/httpClient/httpClient';
import { getUserIncentiveCampaigns } from '../../store/slices/rewards';
import { useAppDispatch, useTypedSelector } from '../../store/store';

import { UploadProgressBar } from '../../common/components/UploadProgressBar/UploadProgressBar';
import { NavigationPanel } from '../../common/components/NavigationPanel/NavigationPanel';
import { useSharePageStyles } from './SharePage.styles';
import { IncentiveBlock } from '../../common/components/IncentiveBlock/IncentiveBlock';
import { GetUserQuestions } from '../../store/slices/ui';

const SharePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id: storyId } = useParams<{ id: string }>();

  const styles = useSharePageStyles();
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    const userId = httpClient.getUserId();
    if (userId) {
      dispatch(getUserIncentiveCampaigns({ userId }));
      dispatch(GetUserQuestions(userId)).then(() => {
        setHasLoaded(true);
      });
    } else {
      setHasLoaded(true);
    }
  }, [dispatch]);

  const { shareStory, isLoading } = useShareStory({
    storyId,
    refetchUntilModerated: true,
  });

  const { currentCampaign } = useTypedSelector((state) => state.rewards);
  const { isLoading: isQuestionsLoading } = useTypedSelector((state) => state.ui.questions);

  const isStoryLoading = isLoading || !shareStory || isQuestionsLoading || !hasLoaded;
  if (isStoryLoading) return <></>;

  return (
    <>
      <Box position="fixed" bottom="0" width="100vw">
        <NavigationPanel
          activeIndex={currentCampaign?.welcomeVideoUrl && currentCampaign?.showWelcomeVideo ? 2 : 1}
          isSharePage={true}
        />
      </Box>
      <Box className={styles.container}>
        <UploadProgressBar forceShowUploadComplete />
        <IncentiveBlock showQuestions={true} showTopBanner={true} isCompleteScreen={true} />
      </Box>
    </>
  );
};

export default SharePage;
