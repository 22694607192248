import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useTypedSelector } from '../../store/store';
import {
  processRecord,
  reset as resetUploadSlice,
  resetProcessRecord,
  setMimeType,
  setPendingUploadedStory,
  // setProgress,
  setUploadError,
  setIsResumingUpload,
  changeStep,
  setIsUploadInPorgress,
  // CreateStoryRequest,
} from '../../store/slices/upload';
import { getDefaultStoryDetails, getSupportedMimeTypes, getUserStoryDetails } from './utils';
import { ErrorsStrings } from '../../common/localization/en';
import { AppRoutes } from '../../common/constants/routes';
import { DEFAULT_STORY_DETAILS } from '../../api/constants';
import { ErrorDialog } from '../../common/components/ErrorDialog/ErrorDialog';
import { isSafari } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { CreateVideo } from '../../common/components/CreateVideo/CreateVideo';
import { EventNames, maxSizeUploadedUserFile } from '../../common/constants/constants';
import { useClientType } from '../../services/hooks/useClientType';
import { useHandleSubmitUploadedFile } from '../../services/hooks/video-handlers/useHandleSubmitUploadedFile';
import { RecordVideoComponent } from './RecordVideoComponent/RecordVideoComponent';
import { useStyles } from './VideoToolPage.helper';
import { useTrackEvent } from '../../common/hooks/useTrackEvent';
import { RecordErrorModal } from '../../common/components/RecordErrorModal/RecordErrorModal';
import { usePrevious } from '../../services/hooks/usePrevious';
import { UploadProcessStep } from '../../store/storeModels';
import { VideoUploadingPage } from '../VideoUploadingPage/VideoUploadingPage';
import VerificationScreen from '../VerificationScreen/VerificationScreen';
import { GetUserQuestions, setSignUpFlowActiveTabIndex } from '../../store/slices/ui';
import { trackEvent as globalTrackEvent } from '../../../utils/analytics/analytics';
// import { StoryVideosApiModel } from '../../api/models/videos';

interface Props {
  isButtonsDisabled: boolean;
  isRecordVideoScreen: boolean;
  setIsRecordVideoScreen: (isRecordScreen: boolean) => void;
  onUploadVideoStart: () => Promise<boolean>;
  captchaResponse: string;
}

export const VideoToolPage: FC<Props> = ({
  isButtonsDisabled,
  isRecordVideoScreen,
  setIsRecordVideoScreen,
  onUploadVideoStart,
  captchaResponse,
}) => {
  const [blob, setBlob] = useState<undefined | string>();
  const [recordRender, setRecordRender] = useState(Date.now());
  const [file, setFile] = useState<null | File>(null);
  const [storyId, setStoryId] = useState('');
  const [isErrorAlert, setIsErrorAlert] = useState(false);

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const uploadingFileRef = useRef<File | null>(null);
  const { currentStep, isUploadError } = useTypedSelector((state) => state.upload);
  const { currentCampaign } = useTypedSelector((state) => state.rewards);

  const {
    defaultVideoDetails,
    returnUrl,
    displayName,
    name,
    phoneNumber,
    id: userId,
  } = useTypedSelector((state) => state.me);
  const { account, accountAttributes } = useTypedSelector((state) => state.account);

  const [isRecordError, setIsRecordError] = useState(false);

  const [camera, setCamera] = useState<'user' | 'environment'>('user');
  const previousCameraOption = usePrevious(camera);
  // const [isOnlineRegister, setIsOnlineRegistered] = useState(false);

  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    if (userId) {
      dispatch(GetUserQuestions(userId));
    }
  }, [userId]);

  useEffect(() => {
    // if phone is not verified -> user will be redirected to story page from phone-verification screen
    if (storyId && phoneNumber) {
      history.push(`${AppRoutes.Share}/${storyId}`);
      const isWelcomeVideoEnabled = !!currentCampaign?.welcomeVideoUrl && currentCampaign.showWelcomeVideo;
      dispatch(setSignUpFlowActiveTabIndex(isWelcomeVideoEnabled ? 1 : 0));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyId, phoneNumber]);
  const { supportedMimeType, pendingUploadedStory, sendSMSNotification } = useTypedSelector((state) => state.upload);
  const { isAthleticSolutionsClient } = useClientType();

  const phoneVerifiedRef = useRef(phoneNumber);
  const smsNotificationRef = useRef(sendSMSNotification);
  const pendingStoryRef = useRef(pendingUploadedStory);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        console.log('Message received from Service Worker:', event.data);
        if (event.data && event.data.type === 'UPDATE_FROM_SW') {
          if (!phoneNumber) {
            dispatch(setIsResumingUpload(false));
            dispatch(setUploadError(false));
            dispatch(changeStep(UploadProcessStep.Uploaded));
            dispatch(setIsUploadInPorgress(false));
          } else {
            dispatch(setIsResumingUpload(false));
            dispatch(setUploadError(false));
            dispatch(changeStep(UploadProcessStep.Uploaded));
            dispatch(setIsUploadInPorgress(false));
            history.push(`/share/${event.data.payload.id}`);
            console.log('Service Worker says:', event.data.payload);
          }
          // Handle the message payload
        }
        if (event.data && event.data.type === 'UPDATE_FROM_SW_RESUME_UPLOAD') {
          // Handle the message payload
          localStorage.setItem('isUploadInProgress', '');
          dispatch(setUploadError(false));
          dispatch(setIsResumingUpload(true));
          dispatch(setIsUploadInPorgress(true));
        }
      });
    }
  }, []);
  //UPDATE_FROM_SW_RESUME_UPLOAD
  useEffect(() => {
    phoneVerifiedRef.current = phoneNumber;
    smsNotificationRef.current = sendSMSNotification;
    pendingStoryRef.current = pendingUploadedStory;
  }, [phoneNumber, sendSMSNotification, pendingUploadedStory]);

  const storyDetails = useMemo(() => {
    const properties = getDefaultStoryDetails(
      defaultVideoDetails,
      accountAttributes.properties?.['webapp.default-story-details'],
      DEFAULT_STORY_DETAILS(),
      returnUrl,
    );
    const userName = displayName || name;
    return getUserStoryDetails(properties, userName, account.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayName, name, account, defaultVideoDetails, accountAttributes]);

  useEffect(() => {
    if (file && file.size >= maxSizeUploadedUserFile) {
      setIsErrorAlert(true);
      trackEvent(EventNames.FileSizeLimitError, {
        size: file.size,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsErrorAlert, file]);

  useEffect(() => {
    if (!isSafari) {
      const mimeType = getSupportedMimeTypes()[0];
      dispatch(setMimeType(mimeType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const {
    handleUploadedFile: uploadStory,
    handleSubmitVideo,
    postStory,
    restartUpload,
  } = useHandleSubmitUploadedFile(setStoryId, isAthleticSolutionsClient, returnUrl);

  // reset upload slice on unmount
  useEffect(() => {
    dispatch(resetUploadSlice());
    return () => {
      dispatch(resetUploadSlice());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(async () => {
      if (blob) {
        const fileBlob = await fetch(blob).then((res) => res.blob());
        if (fileBlob.size >= maxSizeUploadedUserFile) {
          setIsErrorAlert(true);
          trackEvent(EventNames.FileSizeLimitError, {
            size: fileBlob.size,
          });
        } else {
          const webappRecorded = true;
          let success;
          try {
            success = await onUploadVideoStart();
          } catch (err) {
            console.log('error caught here');
            globalTrackEvent({
              action: EventNames.Upload_Failed,
              accountId: account.id,
              errorMessage: JSON.stringify(err),
            });
            dispatch(setUploadError(true));
          }

          if (success) {
            handleSubmitVideo(blob, storyDetails, true, supportedMimeType, smsNotificationRef, webappRecorded);
            trackEvent(EventNames.SubmitRecordedVideo, {
              size: fileBlob.size,
            });
          }
        }
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blob]);

  useEffect(() => {
    if (isRecordVideoScreen) {
      dispatch(processRecord());
    }
  }, [isRecordVideoScreen, dispatch, processRecord]);

  const remount = useCallback(() => {
    setRecordRender((d) => d + 1);
    setIsRecordVideoScreen(false);
    dispatch(resetProcessRecord());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setRecordRender]);

  const refreshRecorder = useCallback(() => {
    setRecordRender((d) => d + 1);
    setIsRecordVideoScreen(false);
    setIsRecordVideoScreen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setRecordRender]);

  const resetVideo = () => {
    setIsErrorAlert(false);
    setFile(null);
    refreshRecorder();
  };

  const handleStoryUpload = useCallback(
    async (file: File | null, { webappRecorded }: { webappRecorded: boolean }) => {
      uploadStory({
        file,
        details: storyDetails,
        isPublic: true,
        videoPos: { x: 0, y: 0, width: 0 },
        shouldPostStory: false,
        webappRecorded,
      }).then((story) => {
        if (!story) {
          dispatch(setUploadError(true));
          return;
        }

        const storyWithNotification = { ...story, sendSMSNotification: smsNotificationRef.current };
        postStory(storyWithNotification).then(() => {
          uploadingFileRef.current = null;
        });

        if (!phoneVerifiedRef.current) {
          dispatch(setPendingUploadedStory(storyWithNotification));
        }
      });
    },
    [sendSMSNotification],
  );

  const handleVideoUploadRestart = async () => {
    trackEvent(EventNames.resume_button);
    const isUploadInProgress = localStorage.getItem('isUploadInProgress');
    if (isUploadInProgress) {
      return;
    }
    if (!userId) {
      let success;
      try {
        success = await onUploadVideoStart();
      } catch (err) {
        console.log('error caught here');
        globalTrackEvent({
          action: EventNames.Upload_Failed,
          accountId: account.id,
          errorMessage: JSON.stringify(err),
        });
        dispatch(setUploadError(true));
      }

      if (success) {
        dispatch(setUploadError(false));
        restartUpload().then((createStoryRequest) => {
          if (!createStoryRequest) {
            dispatch(setUploadError(true));
            return;
          }
          const storyWithNotification = { ...createStoryRequest, sendSMSNotification: smsNotificationRef.current };
          postStory(storyWithNotification).then(() => {
            uploadingFileRef.current = null;
          });

          if (!phoneVerifiedRef.current) {
            dispatch(setPendingUploadedStory(storyWithNotification));
          }
        });
      }
    }
    dispatch(setUploadError(false));
    restartUpload().then((createStoryRequest) => {
      if (!createStoryRequest) {
        dispatch(setUploadError(true));
        return;
      }
      const storyWithNotification = { ...createStoryRequest, sendSMSNotification: smsNotificationRef.current };
      postStory(storyWithNotification).then(() => {
        uploadingFileRef.current = null;
      });

      if (!phoneVerifiedRef.current) {
        dispatch(setPendingUploadedStory(storyWithNotification));
      }
    });

    // handleStoryUpload(uploadingFileRef.current, { webappRecorded: false });
  };

  useEffect(() => {
    if (camera && previousCameraOption && camera !== previousCameraOption) {
      setIsRecordVideoScreen(true);
    }
  }, [camera, previousCameraOption]);

  if (Object.keys(accountAttributes.properties).length === 0) {
    return <></>;
  }

  if (currentStep === UploadProcessStep.UploadVideo) {
    return (
      <div className={classes.wrapper}>
        <ErrorDialog errorMessage={ErrorsStrings.FileSizeExceeded} isOpen={isErrorAlert} onClose={resetVideo} />
        <RecordErrorModal
          isOpen={isRecordError}
          onClose={() => {
            setIsRecordError(false);
          }}
        />

        {isRecordVideoScreen ? (
          <>
            <RecordVideoComponent
              key={recordRender}
              setBlob={setBlob}
              setFile={setFile}
              onFileChange={(newFile) => {
                trackEvent(EventNames.Upload_Initiated);
                uploadingFileRef.current = newFile;
                handleStoryUpload(newFile, { webappRecorded: true });
              }}
              handleRemount={remount}
              setStoryId={setStoryId}
              onRefresh={refreshRecorder}
              setIsRecordError={setIsRecordError}
              camera={camera}
              toggleCamera={() => {
                setCamera(camera === 'user' ? 'environment' : 'user');
              }}
            />
          </>
        ) : (
          <>
            <CreateVideo
              file={file}
              setShowRecord={setIsRecordVideoScreen}
              isButtonsDisabled={isButtonsDisabled}
              onFileChange={async (newFile) => {
                trackEvent(EventNames.Upload_Initiated);
                uploadingFileRef.current = newFile;
                const success = await onUploadVideoStart();
                if (success) {
                  handleStoryUpload(newFile, { webappRecorded: false });
                }
              }}
            />
          </>
        )}
      </div>
    );
  }

  if (!phoneNumber && !isUploadError) {
    return (
      <>
        <VerificationScreen captchaResponse={captchaResponse} />
      </>
    );
  }

  if (
    [UploadProcessStep.VideoUploading, UploadProcessStep.Uploaded, UploadProcessStep.PrepareVideo].includes(currentStep)
  ) {
    return <VideoUploadingPage onRestartClick={handleVideoUploadRestart} />;
  }

  return <></>;
};
