import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { useStyles } from './SignUpScreen.styles';
import { Typography } from '@mui/material';
import { SignUpScreenStrings } from '../../../common/localization/en';
import { getValidationSchema } from '../../MagicLinkPage/MagicLinkForm/MagicLinkForm.helper';
import { useFormik } from 'formik';
import { useClientType } from '../../../services/hooks/useClientType';
import { TermsOfUse } from './TermsOfUse/TermsOfUse';
import { useAppDispatch, useTypedSelector } from '../../../store/store';
import { VideoToolPage } from '../../VideoToolPage/VideoToolPage';
import { useQuery } from '../../../services/hooks/useQuery';
import { loginUnverified } from '../../../store/slices/me';
import { ReCaptcha, ReCaptchaRefType } from '../../../common/components/FormComponents/ReCAPTCHA/ReCaptcha';
import { EventNames } from '../../../common/constants/constants';
import { useTrackEvent } from '../../../common/hooks/useTrackEvent';
import { getAppLinkSource } from '../../../services/utilities';
import { changeStep } from '../../../store/slices/upload';
import { UploadProcessStep } from '../../../store/storeModels';
import { setHideCrispChat } from '../../../store/slices/ui';
import { CampaignTypes } from '../../../api/models/rewards';

interface Props {
  isRecordVideoScreen: boolean;
  setIsRecordVideoScreen: (isRecordScreen: boolean) => void;
}

export const SignUpScreen: FC<Props> = ({ isRecordVideoScreen, setIsRecordVideoScreen }) => {
  const classes = useStyles();
  const { isTrubluClient } = useClientType();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const {
    account: { id: accountId },
  } = useTypedSelector((state) => state.account);
  const { hideCrispChat } = useTypedSelector((state) => state.ui);
  const [isSignUpFormHidden, setIsSignUpFormHidden] = useState(false);
  const [isVerificationScreen, setIsVerificationScreen] = useState(false);
  const [captcharesponse, setCaptcharesponse] = useState('');
  const { currentCampaign } = useTypedSelector((state) => state.rewards);

  const isCustomRewardCampaign = currentCampaign?.campaignType === CampaignTypes.CUSTOM_REWARD;
  const isMonthlyGiveawayCampaign = currentCampaign?.campaignType === CampaignTypes.MONTHLY_GIVEAWAY;
  const isNoRewardCampaign = currentCampaign?.campaignType === CampaignTypes.NO_REWARD;

  //   const questions = [IncentiveBlockStrings.Question1, IncentiveBlockStrings.Question2];
  const { isUVMClient } = useClientType();
  // const { signUpFlowActiveTabIndex } = useTypedSelector((state) => state.ui);

  const {
    id: userId,
    firstName: meFirstName,
    lastName: meLastName,
    registerErrorType,
  } = useTypedSelector((state) => state.me);

  const query = useQuery();
  const [isEnterpriseCaptcha, setIsEnterpriseCaptcha] = useState(true);
  const [captcha, setCaptcha] = useState<string | null>('');

  const { firstName, lastName, phoneNumber } = {
    firstName: query.get('svUn')?.split(' ')[0] || '',
    lastName: query.get('svUn')?.split(' ')[1],
    phoneNumber: query.get('phone') || '',
  };

  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean | null>(!isTrubluClient || null);
  const ecaptchaRef = useRef<ReCaptchaRefType>(null);

  const initialValues = {
    firstName: firstName || meFirstName,
    lastName: lastName || meLastName,
  };

  // type InitialValues = typeof initialValues;

  const { values } = useFormik({
    initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: getValidationSchema({
      firstName: true,
      lastName: true,
      email: false,
      phoneNumber: false,
    }),
    onSubmit: () => {},
  });

  useEffect(() => {
    if (hideCrispChat) {
      dispatch(setHideCrispChat(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsSignUpFormHidden(isRecordVideoScreen || isVerificationScreen);
  }, [isRecordVideoScreen, isVerificationScreen]);

  const submitAuthHandler = useCallback(async (): Promise<{ success: boolean }> => {
    if (userId) {
      return { success: true };
    }

    const enterpriseCaptchaResponse =
      isEnterpriseCaptcha && navigator.onLine ? await ecaptchaRef.current?.executeAsync() : null;
    setCaptcharesponse(enterpriseCaptchaResponse || captcha || '');

    if (!isEnterpriseCaptcha && !captcha) {
      setIsEnterpriseCaptcha(false);
      return { success: false };
    }

    const campaignId = localStorage.getItem('campaignId');
    const source = getAppLinkSource({ magicLink: false });

    const data = await dispatch(
      loginUnverified({
        email: null,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: phoneNumber || null,
        accountId,
        isEnterpriseCaptcha,
        captcha: enterpriseCaptchaResponse || captcha || '',
        source,
        ...(campaignId && { campaignId }),
      }),
    );

    if (data.payload === 418) {
      setIsEnterpriseCaptcha(false);
      return { success: false };
    }

    return { success: true };
  }, [
    dispatch,
    userId,
    isEnterpriseCaptcha,
    captcha,
    values.firstName,
    values.lastName,
    phoneNumber,
    accountId,
    registerErrorType,
  ]);

  // const isError = (fieldName: keyof InitialValues) => {
  //   return !!(touched[fieldName] && errors[fieldName]);
  // };

  // const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   setFieldTouched(event.target.name);
  //   handleChange(event);
  // };

  // const showFormError = Object.keys(initialValues).some((key) => {
  //   return isError(key as keyof InitialValues);
  // });

  const onCaptchaChange = (value: string | null) => {
    setCaptcha(value);

    if (!value) {
      setIsEnterpriseCaptcha(false);
    }
  };

  // const isButtonsDisabled = (!isValid && !userId) || !isTermsAccepted || !captcha;
  const isButtonEnabled = (isTermsAccepted && userId) || (isTermsAccepted && (isEnterpriseCaptcha || captcha));

  const hideForUWMCustom = isUVMClient && isCustomRewardCampaign 

  return (
    <Box className={classes.container}>
      {!isSignUpFormHidden && (
        <Box className={classes.topWrapper}>
          <Typography className={classes.getStartedText}>{SignUpScreenStrings.GetStarted}</Typography>
          <Typography className={classes.recordVideoText}>{SignUpScreenStrings.UploadOrRecordVideo}</Typography>
        </Box>
      )}

      <Box
        className={classes.signUpFormWrapper}
        sx={{
          bottom: isSignUpFormHidden
            ? 'unset'
            : window.innerHeight < 615
            ? `${isTrubluClient ? 0 : 43}px`
            : `${isTrubluClient ? 35 : 80}px`,
          top: isSignUpFormHidden ? '0' : 'unset',
        }}
      >
        {!isRecordVideoScreen && !isSignUpFormHidden && !isNoRewardCampaign && !hideForUWMCustom && (
          <Box sx={{ marginBottom: '80px' }}>
            {isMonthlyGiveawayCampaign ? (
              <Typography className={classes.getStartedText}>{SignUpScreenStrings.SweepStakeText1MG}</Typography>
            ) : (
              <Typography className={classes.getStartedText}>{SignUpScreenStrings.SweepStakeText1}</Typography>
            )}
            <Typography className={classes.sweepStakeTitle}>{SignUpScreenStrings.SweepStakeText2}</Typography>
            <Typography className={classes.sweepStakeDescription}>{SignUpScreenStrings.SweepStakeText3}</Typography>
          </Box>
        )}
        {/* {!userId && !isSignUpFormHidden && (
          <>
            <Typography className={classes.nameText}>{SignUpScreenStrings.Name}</Typography>
            <Input
              className={classes.input}
              value={values.firstName}
              onChange={handleFieldChange}
              disableUnderline={true}
              error={isError('firstName')}
              name="firstName"
              placeholder={SignUpScreenStrings.First}
              onBlur={handleBlur}
            />

            <Input
              className={classes.input}
              value={values.lastName}
              onChange={handleFieldChange}
              disableUnderline={true}
              error={isError('lastName')}
              name="lastName"
              placeholder={SignUpScreenStrings.Last}
              onBlur={handleBlur}
            />
            {showFormError && (
              <Typography className={classes.errorText}>{errors.firstName || errors.lastName}</Typography>
            )}
          </>
        )} */}
        <VideoToolPage
          isButtonsDisabled={!isButtonEnabled}
          isRecordVideoScreen={isRecordVideoScreen}
          setIsRecordVideoScreen={setIsRecordVideoScreen}
          captchaResponse={captcharesponse}
          onUploadVideoStart={async () => {
            const { success } = await submitAuthHandler();
            if (!success) return false;

            setIsVerificationScreen(true);
            setIsRecordVideoScreen(false);
            dispatch(changeStep(UploadProcessStep.VideoUploading));
            // dispatch(setSignUpFlowActiveTabIndex(signUpFlowActiveTabIndex + 1));
            return true;
          }}
        />
        {!isSignUpFormHidden && (
          <Box className={classes.termsWrapper}>
            <TermsOfUse
              isTermsAccepted={isTermsAccepted}
              onChange={(isChecked) => {
                setIsTermsAccepted(isChecked);
              }}
            />
          </Box>
        )}

        {!userId && (
          <Box display={isSignUpFormHidden ? 'none' : 'block'} className={classes.capthaWrapper}>
            <ReCaptcha
              onChange={onCaptchaChange}
              key="captcha"
              ref={ecaptchaRef}
              onError={() => {
                setIsEnterpriseCaptcha(false);
              }}
              onExpired={() => {
                setIsEnterpriseCaptcha(false);
              }}
              isEnterprise={isEnterpriseCaptcha}
              onClick={() => {
                trackEvent(EventNames.Captcha_Checkbox_Click);
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
